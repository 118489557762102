<template>
 <router-view :key="$route.fullPath"/>
</template>

<script>
export default {
  name: 'Game'
}
</script>

<style scoped>

</style>
